import React, { useState } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import NavbarItem from "./NavbarItem"
import VerticalDivider from "../verticalDivider"
import {
  AiFillCaretDown,
  AiOutlineArrowDown,
  AiOutlineClose,
  AiOutlineMenu,
} from "react-icons/ai"

const MobileNavLink = ({
  to = "#",
  className,
  name = "*Error*",
  url = null,
}) => {
  if (url === null) {
    return (
      <li className="grid uppercase ">
        <Link
          to={`/${to}`}
          activeClassName="bg-secondary text-white"
          className=" hover:bg-secondary hover:text-white py-3 pl-5"
        >
          {name}
        </Link>
      </li>
    )
  } else
    return (
      <li className="grid uppercase ">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={`hover:bg-secondary hover:text-white py-3 pl-5`}
        >
          {name}
        </a>
      </li>
    )
}

const NavBar = () => {
  const [isBurgerOpen, SetIsBurgerOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      sanityLogo: sanitySettings {
        logoImage {
          asset {
            fluid(maxHeight: 200) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }

      defaultNavLogo: file(relativePath: { eq: "SLRPSI Horizontal.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      customPages: allSanityCustomPage(
        filter: { includeInMenu: { eq: true } }
      ) {
        edges {
          node {
            id
            title
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const { defaultNavLogo, sanityLogo, customPages } = data

  const customPageArray = customPages.edges

  const customPageLinks = customPageArray.map(({ node: customPage }) => {
    return {
      name: customPage.title,
      handle: `page/${customPage.slug.current}`,
      key: `${customPage.id}-${customPage.slug.current}`,
    }
  })

  // modify this to accept logo from sanity
  const navLogo = sanityLogo?.logoImage?.asset?.fluid
    ? sanityLogo.logoImage.asset.fluid
    : defaultNavLogo.childImageSharp.fluid

  // These are links for the desktop menu
  const navLinks = [
    {
      name: "Home",
      handle: "",
      key: "home",
    },
    {
      name: "About Us",
      handle: "about",
      key: "about",
    },

    {
      name: "Opportunities",
      handle: "opportunities",
      key: "opportunities",
    },

    {
      name: "News",
      handle: "news",
      key: "news",
    },
    {
      name: "Contact Us",
      handle: "contact",
      key: "contact",
    },
  ]

  // Links for the "More" menu
  const moreLinks = [
    {
      name: "Downloads",
      handle: "downloads",
      key: "downloads",
    },
    {
      name: "Lifestyle",
      handle: "lifestyle",
      key: "lifestyle",
    },
    {
      name: "Education",
      handle: "education",
      key: "education",
    },
    {
      name: "Podcasts",
      handle: "podcasts",
      key: "podcasts",
    },

    {
      name: "Gallery",
      handle: "gallery",
      key: "gallery",
    },
  ]
    .concat(customPageLinks)
    .concat([
      { name: "Admin", url: "https://slrpsi.sanity.studio", key: "admin" },
    ])

  // These are links for the mobile menu

  const mobileLinks = [
    {
      name: "Home",
      handle: "",
      key: "mobile-nav-home",
    },
    {
      name: "News",
      handle: "news",
      key: "mobile-nav-news",
    },
    {
      name: "About Us",
      handle: "about",
      key: "mobile-nav-about",
    },
    {
      name: "Lifestyle",
      handle: "lifestyle",
      key: "mobile-nav-lifestyle",
    },
    {
      name: "Opportunities",
      handle: "opportunities",
      key: "mobile-nav-opportunities",
    },
    {
      name: "Downloads",
      handle: "downloads",
      key: "mobile-nav-downloads",
    },
    {
      name: "Education",
      handle: "education",
      key: "mobile-nav-education",
    },
    {
      name: "Podcasts",
      handle: "podcasts",
      key: "mobile-nav-podcasts",
    },

    {
      name: "Gallery",
      handle: "gallery",
      key: "mobile-nav-gallery",
    },
    {
      name: "Contact Us",
      handle: "contact",
      key: "mobile-nav-contact",
    },
  ]
    .concat(customPageLinks)
    .concat([
      {
        name: "Admin",
        url: "https://slrpsi.sanity.studio",
        key: "mobile-nav-admin",
      },
    ])

  return (
    <header>
      <nav className="relative">
        {/* navbar logo */}
        {/* desktop nav */}

        <div className="flex justify-center mx-auto ">
          <Link
            to="/"
            className=" w-60 sm:w-64 md:w-72 py-2 transition duration-200 ease-in-out transform hover:scale-105 "
          >
            <Img className="" fluid={navLogo} className="" />
          </Link>
          <div className="hidden xl:flex  justify-center items-stretch  ml-10">
            {navLinks.map((item, index) => {
              return (
                <NavbarItem
                  handle={item.handle}
                  name={item.name}
                  key={`${item.name}-${index}`}
                  className=""
                />
              )
            })}
            <NavbarItem
              name={
                <span className="flex items-center gap-x-1">
                  More
                  <AiFillCaretDown />
                </span>
              }
              dropdown={moreLinks}
              handle={null}
              dropdownWrap
            />
          </div>
        </div>
        {/* Burger Icon */}
        <button
          className="flex flex-col justify-center xl:hidden mr-2 text-2xl uppercase cursor-pointer text-primary text-center h-full font-bold absolute top-0 right-0 transition duration-100 ease-in-out transform "
          onClick={() => SetIsBurgerOpen(!isBurgerOpen)}
        >
          {isBurgerOpen ? (
            <AiOutlineClose className="text-2xl " />
          ) : (
            <AiOutlineMenu className="text-2xl" />
          )}
        </button>
      </nav>
      {isBurgerOpen && (
        <div className=" bg-gray-100 text-xl xl:hidden ">
          <ul className="flex flex-col p-2.5">
            {mobileLinks.map((link, index) => (
              <MobileNavLink
                key={`mobile-${index}-${link.key}`}
                to={link.handle}
                name={link.name}
                url={link.url}
              />
            ))}
          </ul>
        </div>
      )}
    </header>
  )
}

export default NavBar
