import React from "react"
import FooterForm from "./footerForm"
import { Link } from "gatsby"

const footerLinks = [
  {
    name: "Home",
    handle: "home",
    key: "home",
  },
  {
    name: "News & Events",
    handle: "news",
    key: "news",
  },
  {
    name: "Opportunities",
    handle: "opportunities",
    key: "opportunities",
  },
  {
    name: "Gallery",
    handle: "gallery",
    key: "gallery",
  },
  {
    name: "Contact Us",
    handle: "contact",
    key: "contact",
  },
]

const Footer = ({ footerClass }) => {
  return (
    <>
      <div
        className={`${footerClass} bg-secondary grid gap-y-5 lg:flex flex-wrap items-center justify-around  text-sm 2xl:text-xl text-white w-full  py-10 px-10`}
      >
        <div className="text-center lg:text-left">
          <div className="font-bold">
            Sioux Lookout Regional Physicians' Services Inc.
          </div>
          <div className="italic">
            Sioux Lookout First Nations Health Authority
          </div>
          <div> Phone: (807) 738-0414</div>
          <div> Toll Free: 1-800-842-0681</div>
          <div> Fax: (807) 737-4349</div>
          <a
            href="mailto:recruitment@slfnha.com"
            className="underline hover:text-fifth"
          >
            recruitment@slfnha.com
          </a>
        </div>

        <div className="grid grid-cols-1">
          {footerLinks &&
            footerLinks.map((item) => (
              <Link
                to={`/${item.handle}`}
                key={item.key}
                className="text-center uppercase tracking-wide font-bold text-xl hover:text-fifth"
                title={item.name}
              >
                {item.name}
              </Link>
            ))}
        </div>

        <div className="grid grid-cols-1  justify-items-center lg:justify-items-start ">
          <p className="">Join Our Newsletter:</p>
          <FooterForm />
        </div>
      </div>
      <div className="bg-quaternary grid grid-cols-1 place-items-center text-xs 2xl:text-xl text-white">
        <p className=" text-center">
          © {new Date().getFullYear()} Sioux Lookout Regional Physicians
          Services Inc.
        </p>
        <p className=" text-center">
          Website by{" "}
          <a
            href="https://www.borealisweb.ca"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary hover:text-white hover:underline"
          >
            Borealis Web Development
          </a>
        </p>
      </div>
    </>
  )
}

export default Footer
