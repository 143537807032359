import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { AiOutlineArrowUp } from "react-icons/ai"

const BackToTop = ({ offset = 0 }) => {
  if (offset >= 500) {
    return (
      <div className="w-min grid fixed bottom-4 right-4 transition duration-100 ease-in-out transform hover:scale-110 opacity-70 ">
        <Link
          className="text-white border-2 border-fifth bg-fifth  hover:text-fifth hover:bg-white rounded-full p-1 md:p-2  "
          to={`#top`}
          title="Back to Top"
        >
          <AiOutlineArrowUp className=" text-2xl md:text-3xl " />
        </Link>
      </div>
    )
  }
  return null
}

export default BackToTop
