import React from "react"
import { Link } from "gatsby"
import DropdownMenu from "./dropDownMenu"
import VerticalDivider from "../verticalDivider"

// default to empty strings because I may use it for other purposes (that don't use these props)
const NavbarItem = ({
  handle = null,
  itemKey = "",
  name = null,
  dropdown = null,
  fluid = null,
  dropdownWrap = false,
  className = "",
}) => {
  const navLinkClass = `px-5 2xl:px-10 uppercase tracking-wide font-semiBold text-black text-center group-hover:bg-blue-500 group-hover:text-white text-xl h-full grid items-center py-2 ${className}  `

  return (
    // "group" allows for child elements to be affected by hover over a parent element
    // https://tailwindcss.com/docs/pseudo-class-variants#group-hover
    // https://tailwindcss.com/docs/configuring-variants
    <>
      <div className="group relative border-r-2 border-gray-300 last:border-0 ">
        {/* If no handle is provided, render a visually identical non-link item. Empty string is OK (i.e. Index Page) */}
        {handle != null ? (
          <Link
            to={`/${handle}`}
            key={itemKey}
            className={navLinkClass}
            activeClassName="text-blue-dark"
            title={name}
          >
            <div className="">{name}</div>
          </Link>
        ) : (
          <div className={`cursor-pointer ${navLinkClass}`}>{name}</div>
        )}
        {dropdown && (
          <DropdownMenu dropdown={dropdown} dropdownWrap={dropdownWrap} />
        )}
      </div>
    </>
  )
}

export default NavbarItem
