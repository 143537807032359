import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import NavBar from "./navbar/navbar"
import Footer from "./footer"
import BackToTop from "./BackToTop"

const Layout = ({ children, footerClass }) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setOffset(window.pageYOffset)
    })

    return window.removeEventListener("scroll", setOffset(window.pageYOffset))
  }, [])

  return (
    <div id="top" className="min-h-screen  flex flex-col">
      <NavBar />
      <div
        className={`grid  bg-vector w-full font-sans Roboto z-0 relative flex-grow`}
      >
        {children}
        <BackToTop offset={offset} />
      </div>
      <Footer footerClass={footerClass} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
