import React from "react"

const FooterForm = () => {
  return (
    <form
      className=""
      name="email_list"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/success"
    >
      {/* If this hidden input isn't included, the form won't get to where it needs to go */}
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      {/* ref: https://cobwwweb.com/how-to-use-netlify-forms-with-gatsby || https://stackoverflow.com/questions/61318577/netify-gatsby-form-submission-not-working-with-custom-success-component*/}
      {/* Don't forget this field - won't work without it! */}
      {/* DOCS: https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#form-handling-with-static-site-generators */}
      <input type="hidden" name="form-name" value="email_list" />
      {/* HP */}
      <div className="hidden">
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
        <input name="bot-field" />
      </div>
      {/* End HP */}
      <div className="bg-secondary  grid grid-cols-1">
        <input
          className="bg-secondary placeholder-white text-center border-b-2 border-quaternary pb-1 placeholder-gray-400"
          name="name"
          id="name"
          type="text"
          placeholder="Name"
          required
        />

        <input
          className="bg-secondary placeholder-white text-center border-b-2 border-quaternary pb-1 placeholder-gray-400"
          name="email"
          id="email"
          type="email"
          placeholder="Email Address"
          required
        />

        <button
          type="submit"
          className="bg-quaternary text-white font-semibold mt-3 tracking-wide hover:bg-white hover:text-black "
        >
          Sign Up
        </button>
      </div>
    </form>
  )
}
export default FooterForm
